<template>
  <div>
    <div class="container max-w-screen-lg item-center mx-auto" id="insurance-detail">
      <div class="my-8 max-w-screen-sm mx-auto">
        <div class="py-4 px-8 w-full bg-primary rounded-t-lg">
          <div class="flex justify-between">
            <div class="flex items-center">
              <img src="@/assets/insurance.svg" class="h-12" alt="" />
              <div class="flex flex-col justify-between text-white ml-4 h-full">
                <p class="font-semibold text-xs">Personal Accident</p>
                <p class="font-medium text-xi">Insurance Non Premium - Rp 10.000/day</p>
                <p class="font-medium text-xi">2 Insured Persons</p>
              </div>
            </div>
            <div class="flex items-end text-white">
              <p class="text-xi font-medium">Coverage Date 04 Aug - 10 Aug 2022</p>
            </div>
          </div>
        </div>
        <div class="py-1.5 text-center bg-ee rounded-b-lg">
          <p class="text-xs font-medium">Insurance Details</p>
        </div>
        <div class="py-4 px-4 text-xs font-semibold">
          <p>Price Details</p>
          <div class="w-full border border-b4 rounded-lg items-center px-2 mt-2 mb-3">
            <div class="px-3 py-1.5 flex justify-between items-center w-full text-base font-semibold">
              <p>Price you pay</p>
              <p class="flex items-center">Rp 120.000<span @click="togglePayment"
                  class="text-rounded flex items-center text-sm justify-center ml-3 cursor-pointer">
                  <font-awesome-icon v-if="openPayment" icon="fa-solid fa-chevron-up" />
                  <font-awesome-icon v-else icon="fa-solid fa-chevron-down" />
                </span></p>
            </div>
            <div class="px-3 py-1.5 flex justify-between items-center w-full text-base font-medium border-b4 border-t"
              :class="openPayment ? 'block' : 'hidden'">
              <p>Personal Accident (2x)</p>
              <p class="flex items-center">Rp 120.000</p>
            </div>
          </div>
          <p>Payment Method</p>
          <div class="text-center mt-7 text-lg">
            <button class="py-2 px-9 bg-yellow rounded-lg font-bold" @click="setModalPayment">Booking Now</button>
          </div>
        </div>
      </div>
    </div>
    <ModalPayment ref="modalPayment" />
  </div>
</template>

<script>
export default {
  name: 'InsuranceDetail',
  components: {
    ModalPayment: ()=>import('@/components/layout/ModalInsurancePayment.vue')
  },
  data() {
    return {
      openPayment: false,
      checkAgree: false,
      modalPayment: false,
    }
  },
  methods: {
    togglePayment: function () {
      this.openPayment = !this.openPayment
    },
    toggleAgree: function () {
      this.checkAgree = !this.checkAgree
    },
    setModalPayment: function () {
      this.$refs.modalPayment.open()
    },
  }
};
</script>
